@font-face {
  font-family: PoppinsLight;
  src: url('../src/assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: Poppins;
  src: url('../src/assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: PoppinsBold;
  src: url('../src/assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: PoppinsMedium;
  src: url('../src/assets/fonts/Poppins-Medium.ttf');
}
.css-v9b9hc {
  z-index: 0 !important;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
}

.pagination-container > li {
  list-style: none;
  display: inline-block;
  margin: 5px;
}
.pagination-container > li > a,
.pagination-container > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: white;
  border-radius: 5px;
}
.pagination-container > li.active > a {
  color: #fff;
  background-color: blue;
  color: white;
}
.pagination-container > li > a:hover {
  background-color: #218838;
  color: white;
}
.page-item {
  color: white;
  background-color: blue;
  border-radius: 5px;
}
.page-item > li.active > a {
  color: white;
  background-color: green;
  border-radius: 5px;
}
.pagination-button {
  /* display: none; */
  color: white;
  background-color: #218838;
  border-radius: 5px;
}
